import SimplexNoise from 'simplex-noise';

const {PI, cos, sin, abs, sqrt, pow, round, random, atan2} = Math;
const TAU = 2 * PI;
const rand = n => n * random();
const fadeInOut = (t, m) => {
    let hm = 0.5 * m;
    return abs((t + hm) % m - hm) / (hm);
};

const circleCount = 10;
const circlePropCount = 8;
const circlePropsLength = circleCount * circlePropCount;
const baseSpeed = 0.05;
const rangeSpeed = 1;
const baseTTL = 200;
const rangeTTL = 400;
const baseRadius = 100;
const rangeRadius = 400;
const rangeHue = 60;
const xOff = 0.0015;
const yOff = 0.0015;
const zOff = 0.0015;
const backgroundColor = 'rgba(20, 23, 31, 1)';

let container;
let canvas;
let ctx;
// let circles;
let circleProps;
let simplex;
let baseHue;

function setup() {

    // This is just for Development HMR Bundle Rebuild of next.js
    // otherwise background instance movement gets faster and faster.....
    // -------------------->
    if(canvas) {
        // console.log('is already defined');
        createCanvas();
        resize();
    } else {
        // console.log('is not defined');
        createCanvas();
        resize();
        initCircles();
        draw();
    }

    // Original
    // --------------------->
    // createCanvas();
    // resize();
    // initCircles();
    // draw();
}

function initCircles() {
    circleProps = new Float32Array(circlePropsLength);
    simplex = new SimplexNoise();
    baseHue = rand(100);

    let i;

    for (i = 0; i < circlePropsLength; i += circlePropCount) {
        initCircle(i);
    }
}

function initCircle(i) {
    let x, y, n, t, speed, vx, vy, life, ttl, radius, hue;

    x = rand(canvas.a.width);
    y = rand(canvas.a.height);
    n = simplex.noise3D(x * xOff, y * yOff, baseHue * zOff);
    t = rand(TAU);
    speed = baseSpeed + rand(rangeSpeed);
    vx = speed * cos(t);
    vy = speed * sin(t);
    life = 0;
    ttl = baseTTL + rand(rangeTTL);
    radius = baseRadius + rand(rangeRadius);
    hue = baseHue + n * rangeHue;

    circleProps.set([x, y, vx, vy, life, ttl, radius, hue], i);
}

function updateCircles() {
    let i;

    baseHue++;

    for (i = 0; i < circlePropsLength; i += circlePropCount) {
        updateCircle(i);
    }
}

function updateCircle(i) {
    let i2 = 1 + i, i3 = 2 + i, i4 = 3 + i, i5 = 4 + i, i6 = 5 + i, i7 = 6 + i, i8 = 7 + i;
    let x, y, vx, vy, life, ttl, radius, hue;

    x = circleProps[i];
    y = circleProps[i2];
    vx = circleProps[i3];
    vy = circleProps[i4];
    life = circleProps[i5];
    ttl = circleProps[i6];
    radius = circleProps[i7];
    hue = circleProps[i8];

    drawCircle(x, y, life, ttl, radius, hue);

    life++;

    circleProps[i] = x + vx;
    circleProps[i2] = y + vy;
    circleProps[i5] = life;

    (checkBounds(x, y, radius) || life > ttl) && initCircle(i);
}

function drawCircle(x, y, life, ttl, radius, hue) {
    ctx.a.save();
    ctx.a.fillStyle = `hsla(${hue},60%,30%,${fadeInOut(life, ttl)})`;
    ctx.a.beginPath();
    ctx.a.arc(x, y, radius, 0, TAU);
    ctx.a.fill();
    ctx.a.closePath();
    ctx.a.restore();
}

function checkBounds(x, y, radius) {
    return (
        x < -radius ||
        x > canvas.a.width + radius ||
        y < -radius ||
        y > canvas.a.height + radius
    );
}

function createCanvas() {
    container = document.querySelector('.ambient-background');
    canvas = {
        a: document.createElement('canvas'),
        b: document.createElement('canvas')
    };
    // canvas.b.style = `
    //     position: fixed;
    //     filter: blur(100px);
    //     transform: scale(2);
    //     z-index: -1;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    // `;
    container.appendChild(canvas.b);
    ctx = {
        a: canvas.a.getContext('2d'),
        b: canvas.b.getContext('2d')
    };
}

function resize() {
    const {innerWidth, innerHeight} = window;

    canvas.a.width = innerWidth;
    canvas.a.height = innerHeight;

    ctx.a.drawImage(canvas.b, 0, 0);

    canvas.b.width = innerWidth;
    canvas.b.height = innerHeight;

    ctx.b.drawImage(canvas.a, 0, 0);
}

function render() {
    ctx.b.save();
    // ctx.b.filter = 'blur(100px)';
    ctx.b.drawImage(canvas.a, 0, 0);
    ctx.b.restore();
}

function draw() {
    ctx.a.clearRect(0, 0, canvas.a.width, canvas.a.height);
    ctx.b.fillStyle = backgroundColor;
    ctx.b.fillRect(0, 0, canvas.b.width, canvas.b.height);
    updateCircles();
    render();
    window.requestAnimationFrame(draw);
}

export {
    setup,
    resize,
}
