import React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import {useEffect} from "react";
import AmbientBackground from "../components/AmbientBackground";

export default function Home({data}) {

    useEffect(() => {

        let smallChatStyles = `
            @keyframes fadein {
                from { opacity: 0; }
                to   { opacity: 1; }
            }
            
            #Smallchat {
                animation: fadein 0.5s;
                animation-timing-function: ease-in-out;
            }
            
            #Smallchat * {
               font-family: 'Lato', sans-serif !important;
               font-weight: 400 !important;
            }
        
            #Smallchat .Layout.Launcher-button {
               background-color: rgba(33, 33, 33, 0.0) !important;
               box-shadow: none !important;
            }
            
            #Smallchat .Layout { 
                background-color: rgba(33, 33, 33, 0.4) !important;
                transition: all 0.2s ease-in-out !important
            }
            
            #Smallchat .Launcher-button .Icon {
                margin-top: 3px;
            }
            
            #Smallchat .Messenger_header { 
                background-color: transparent !important;
            }
        
            #Smallchat .Messages {
                 background-color: transparent !important;
            }
            
            #Smallchat .Messenger_content {
                 background-color: transparent !important;
            }
            
            #Smallchat .Message_content {
                background-color: #f4f6f6 !important;
            }
            
            #Smallchat .Blank p {
                color: rgba(255,255,255, 0.70) !important;
            }
            
            #Smallchat .Blank svg {
                display: none;
            }
            
            #Smallchat .PoweredBy * {
                opacity: 0.5;
                color: #fff !important;
                fill: #fff !important;
                stroke: #fff !important;
            }
            
            #Smallchat .AppIcon {
                background-color: transparent !important;
            }
        `;

        window.Smallchat = {
            config: {
                "slackTeamId": "T6MCX1UKU",
                "scChannelId": "-LbS2W_aFhFCkrzEkdTO",
                "slackChannelId": "GHKNGHAPR",
                "uid": "-LbHfLI3IgLd06LsnlW0",
                "planId": null,
                "accountCreated": 1554017772777
            },
            appearance: {
                "brand_color": "#2C2C46",
                "contact_dismissible": false,
                "contact_enabled": true,
                "contact_field_label": "Email",
                "contact_immediately": false,
                "contact_prompt": "Add your name and email to make sure you see my reply:",
                "contact_reply": "Thanks {{name}}! You'll get a response here or I'll contact you at {{contact}}.",
                "custom_css": smallChatStyles,
                "hide_logo": false,
                "hide_team_icon": false,
                "launcher_pos": "right",
                "launcher_prompt": "contact the host",
                "launcher_type": "button",
                "messenger_blank": "",
                "messenger_entry": "Send a message...",
                "messenger_prompt": "How can I help you?",
                "name_field_label": "Name",
                "offline_greeting": "I'am offline right now but typically respond in about an hr.",
                "text_color": "#FFFFFF"
            },
        };

        let script = document.createElement('script');
        script.async = true;
        script.src = 'https://static.small.chat/messenger.js';
        document.body.appendChild(script);


        // Set current Domain
        let documentWriter = document.getElementById('hostname-writer');
        documentWriter.innerHTML = window.location.href;
        documentWriter.href = window.location.href;


        document.querySelector('main').classList.add('loaded');

    });

    const metaData = data.site.siteMetadata

    function page(props, location) {
        // Split the pathname string into an array of sub-paths
        let myLocation = location.pathname.split('/').join('');

        if (myLocation === '') {
            myLocation = 'Home';
        } else if (myLocation === 'about-us') {
            myLocation = 'About Us';
        } else if (myLocation === 'services-training') {
            myLocation = 'Services  & Training';
        } else if (myLocation === 'carriages-wagons') {
            myLocation = 'Carriages & Wagons';
        } else if (myLocation === 'contact-us') {
            myLocation = 'Contact Us';
        }

        return myLocation
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <html lang="de" />
                {/*<title>{}</title>*/}
                <meta name="robots" content="noindex, nofollow" />
                {/*<meta name="description" content={metaData.description} />*/}
                {/*<meta name="keywords" content={metaData.keywords} />*/}
                <meta name="author" content={metaData.author} />
                {/*<meta name="google-site-verification" content={metaData.siteVerification} />*/}
                <link rel="canonical" href={metaData.siteUrl} />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap" rel="stylesheet" />
            </Helmet>
            <main>
                {/*<Img fluid={data.file.childImageSharp.fluid} className={'image'}/>*/}
                <h1>
                    <b>Hi</b>, if you are seeing this message the website <a id={'hostname-writer'} /> is not available at this time.
                </h1>
            </main>
            <AmbientBackground />
        </>
    );
}

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                #description
                siteUrl
                #keywords
                author
                #siteVerification
            }
        }
    }
`;
