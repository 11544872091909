import React, {useEffect} from "react";
import {setup, resize} from '../utils/ambient-background';

let AmbientBackground = () => {

    useEffect(() => {
        window.addEventListener('load', setup());
        window.addEventListener('resize', resize());
    });

    return (
        <div className={'ambient-background'} />
    )
};

export default AmbientBackground;
